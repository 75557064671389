
export default {
    name: 'visualizer',
    data () {
        return {
          Display:'0px',
          Opacity: 0,
          position: '0',
          transition: 'left 0.3s',
          startPoint: 0,
          prevPositon: 0,
          min: -100,
          offset:100,
          lastTapTime:0,
          ImgWidthM:'100%',
          ImgWidth:'auto',
          DisplayDesc:true,
          description:'',
          elts:[/*{img:'chambre.jpg'},{img:'phone.svg'},{img:'star.svg'},{img:'quote.svg'}*/]
        }
    },
    computed: {
      Position () {
        return this.position+'%'
      }
    },
    methods: {
      resetZoom () {
        this.ImgWidth = 'auto'
        this.ImgWidthM ='100%'
      },
      dragStart (event) {
        event.preventDefault()
        this.startPoint = event.type == 'touchstart' ? event.touches[0].pageX : event.pageX
        this.prevPositon = this.position
        //-------- double tap detection system -------------
        const currentTime = new Date().getTime();
        if (currentTime - this.lastTapTime < 300) {
          this.ImgWidth = this.ImgWidth == 'auto' ? '300%' : 'auto'
          this.ImgWidthM = this.ImgWidthM == '100%' ? '300%' : '100%'
        }
        else this.lastTapTime = currentTime;
      },
      dragging (event) {
        if(this.startPoint != 0) {
          let offset = event.type == 'touchmove' ? event.touches[0].pageX : event.pageX
          //alert(offset)
          //------------------------------------------------------------
          let a = offset - this.startPoint
          a = a/window.innerWidth*60
          let newPosition = parseFloat(this.position) + a
          if(newPosition >= this.min && newPosition <= 0) this.position = newPosition
        }
      },
      dragEnd () {
        let dragDistance = this.prevPositon - this.position
        //------ multiplicateur --------------------------
        let m = 1
        if(Math.abs(dragDistance) > 140 ) m = 2
        else if(Math.abs(dragDistance) > 200 ) m = 3
        else if(Math.abs(dragDistance) > 230 ) m = 4

        let r = this.prevPositon
        if( dragDistance < 0 ) r = this.prevPositon + m*this.offset
        else if(dragDistance > 0) r = this.prevPositon - m*this.offset

        if(r <= this.min) this.position = this.min
        else if(r >= 0) this.position = 0
        else this.position = r - r%this.offset
        this.startPoint = 0
      },
      close () {
        this.Display = '0px'
        this.Opacity = 0
        this.position = '0'
        this.startPoint = 0
        this.prevPositon = 0
        this.DisplayDesc = true
        this.resetZoom()
      },
      open (images,desc) {
        if(images.length == 0) return
        this.elts = images
        this.min = -this.elts.length*100 + 100
        this.Display = "100%"
        this.Opacity = 1
        this.description = desc == null ? '': desc
      }
    }
}
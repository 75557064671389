<template>
  <div class="Myprofile">
    <div class="profileMain">
        <div
          class="profileContent"
          :style="{ left:Position,width:(numb*100)+'%' }"
          @click="$emit('visualize')"
        >
          <div v-for="(image,index) in images" :style="{width:100/numb+'%'}" :key="index" class="profileCont">
            <img class="profileContImg" :src="image">
          </div>
        </div>
    </div>
    <div class="profileInfos">
          <div>{{ infos.nomProduit }}</div>
          <span>
            <span v-show="infos.prixProduit!=null" >{{infos.prixProduit}} FCFA</span>
          </span>
    </div>
    <span v-show="images.length!=0" class="myPButton myButtonL" @click="move(1)"><img src="./img/back.svg"/></span>
    <span v-show="images.length!=0" class="myPButton myButtonR" @click="move(-1)"><img src="./img/next.svg"/></span>
    <span v-show="infos.disponible==1" class="profileDispo" >Disponible</span>
  </div>
</template>
<style src="./myProfile.css">
</style>
<script src="./myProfile.js">
</script>

<template>
<section v-show="this.$store.state.logDisplay.connexion" @click="closing" ref='main' class="inscription">
    <form class = "insMainSection" @submit="submiting" action="#">
        <div class = "insTitle">
            <span>Connexion</span>
            <div v-show="DispErr" class="insError">{{error}}</div>
        </div>
        <div class ="insInput">
            <InputI0 placeholder="Entrez num téléphone" ref="contact" type = "number" />
        </div>
        <div class ="insInput">
            <InputI0 placeholder="Entrez un mot de passe" ref="password" type = "password" pattern="^.{4,22}$" />
        </div>
        <div class="insLaw">
            <span class ="insPolicy">Vous avez oublié votre mot de passe? 
                <span>Essayez de récupérer votre compte</span> </span>
        </div>
        <div @click='loggin' class="insButtonI0" ><ButtonI0 message = "Continuez" /></div>
        <div class = "insOR">
            <span class ="insPolicy">OR</span>
        </div>
        <div class="insLast">
            <a @click="toInscription">Vous n'avez pas de compte? Inscrivez vous</a>
        </div>
    </form>
    <LLoading1 :visible="loading"/>
</section>
</template>
<style src="./inscription.css">
</style>
<script src = "./connexion.js">
</script>

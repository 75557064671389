export default {
  name: 'confirmJ',
  data () {
    return {
      text:`Voulez vous vraiment supprimer ?`
    }
  },
  emits:['clicked'],
  methods: {
    show(text) {
      this.text = text
      this.$store.commit('setDisplayNavConfirm',true)
    },
    clicked (message) {
      this.$emit('clicked',message)
      this.$store.commit('setDisplayNavConfirm',false)
    }
  }
}

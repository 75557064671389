import SearchBar from './SearchBar'
import SearchBar1 from './SearchBar1'
import SearchButt from './button/SearchButt'
import SearchButt1 from './button/SearchButt1'
import Favorite from './button/Favorite'
import Favorite1 from './button/Favorite1' 
import Inscription from '@/components/inscription/Inscription'
import Connexion from '@/components/inscription/Connexion'
import ConfirmJ from '@/components/global/confirm/ConfirmJ'
import Geolocation from './Geolocation'

export default {
  components: {
    SearchBar,
    SearchBar1,
    SearchButt,
    SearchButt1,
    Favorite,
    Favorite1,
    Inscription,
    Connexion,
    ConfirmJ,
    Geolocation
  },
  name: 'jNavBar',
  props: {
    state: {
      type: String,
      default: 'none'
    }
  },
  emits:["inscription","connexion","search","messages"],
  data () {
    return {
      elts: {objectList:true,favorite:false},
      current: 'objectList',
      connected:false,
      placeholder:'Rechercher un objet'
    }
  },
  methods: {
    change (elt) {
      this.$router.push({name:elt})
      //---- to delete
      this.elts[elt] = true
      this.elts[this.current] = false
      this.current = elt
    },
    change1 (elt) {
      if(!this.elts[elt]) {
        this.elts[elt] = true
        this.elts[this.current] = false
        this.current = elt
        this.$router.push({name:elt})
      }
    },
    quickSearch (toSearch) {
      this.$store.commit('setSearch',{page:'favorite',search:toSearch})
    },
    search (toSearch) {
      this.$store.commit('setSearch',{page:this.current,search:toSearch})
    },
    deconnexion () {
      this.$refs.confirm.show("Voulez-vous vraiment vous déconnecter?")
    },
    disconnect (message) {
      if(message === 'yes') { // deconnexion
        localStorage.removeItem('user__')
        this.$store.commit('connexion',{connected: false,id:'',name:'',tel:'',img:''})

        localStorage.removeItem('favorites__')
        this.$store.commit('setFavorites',[])
      }
    },
    loadLocStorage (key,commit) {
      let ls = localStorage.getItem(key)
      if(ls != null ) {
        try {
          let inf = JSON.parse(ls)
          //console.log(inf)
          //inf = inf.filter(tk => tk != null)
          //inf = key == 'stockerSetStock' ? inf.slice(0,300) : inf
          this.$store.commit(commit,inf)
        }
        catch (error) {
          console.log(error)
          this.$store.commit(commit,[])
        }
      }
    },
  },
  watch: {
    '$store.state.toSearch' (newVal) {
      if(newVal.page==="objectListSender") {
        this.$refs.search.search = newVal.search
        this.$refs.search1.search = newVal.search
      }
    },
    '$store.state.user.connected' (newVal) {
      this.connected = newVal
    },
    '$route' (to/*, from*/) {
        this.placeholder = to.name == 'objectList' ? 'Rechercher un objet' : 'Rechercher dans vos boutiques favorites'
        this.change1(to.name)
    }
  },
  mounted () {
    //-------check if connected ------------------------------
    let ls = localStorage.getItem('user__')
    if(ls != null ) { 
      let inf = JSON.parse(ls)
      this.$store.commit('connexion',{...inf,connected: true})
    }
    //---------- load favorite ----------------------------------
    this.loadLocStorage('favorites__','setFavorites')
  }
}

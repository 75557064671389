<template>
    <div class="searchBar">
        <input v-model="search" type="search" @input="$emit('quickSearch',search)" 
            @search="$emit('search',search)" :placeholder="placeholder">
        <div @click="$emit('search',search)" class="searchBarSearch">
            <img src="./img/lens.svg"/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'searcBar',
    props: {
        placeholder:{
            default:''
        }
    },
    emits:["search","quickSearch"],
    data () {
        return {
            search:''
        }
    }
}
</script>
<style>
    .searchBar {    
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    border: solid 2px gray;
    border-radius: 10em;
    display: flex;
    background-color: white;
    overflow: hidden;
    }

    .searchBar > input {
    border: none;
    padding-left: 25px;
    width: 100%; 
    font-size: 14px;
    }

    .searchBarSearch {
    width: 70px;
    min-width: 70px;
    /*background-color: var(--mainColor);*/
    display: flex;justify-content: center;align-items: center;
    cursor: pointer;
    }
    .searchBarSearch > img {
    width: 20px
    }

    /* ---------------- now managment of the responsive ---------------------*/
    @media screen and (max-width: 1000px){
    .searchBarType > select {
        font-size: 14px;
        width: 100px;
    }
}

</style>

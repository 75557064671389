import LLoading1 from '@/components/global/lLoading/LLoading'
import Visualizer from '@/components/global/visualizer/Visualizer'
import Reload from '@/components/global/reload/Reload'

export default {
  name: 'shopArticle',
  components: {
    LLoading1,
    Visualizer,
    Reload
  },
  data () {
    return {
      Display:false,
      loading:false,
      infos:{},
      list:[],
      listAll:[],
      word:''
    }
 },
 computed: {
 },
 methods: {
  show (infos) {
    this.infos = infos
    this.Display = true
    this.getProducts()
  },
  close () {
    if(this.$refs.visualizer.Opacity==1 || this.$refs.reload.Display == true) {
      this.$refs.visualizer.close()
      this.$refs.reload.close()
      return
    }
    this.infos = {}
    this.list = []
    this.listAll = []
    this.word = ''
    this.Display = false
  },
  search () {
    let k = this
    let listAll = this.listAll
    let w = k.removeAccents(k.word.toLowerCase())
    this.list = listAll.filter ((prod)=>{
      let pd = k.removeAccents(prod.nomProduit.toLowerCase())
      if(pd.includes(w)) return true
      return false
    })
  },
  removeAccents (str) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
  },
  setImages (inf) {
    let images =  []
    let bUrl = this.$store.state.stockwareUrl + 'images/'
    if(inf.produitImage0 != '' ) images.push(bUrl+inf.produitImage0)
    if(inf.produitImage1 != '' ) images.push(bUrl+inf.produitImage1)
    if(inf.produitImage2 != '' ) images.push(bUrl+inf.produitImage2)
    if(inf.produitImage3 != '' ) images.push(bUrl+inf.produitImage3)
    return images
  },
  getProducts () {
    //console.log(this.infos.session+this.infos.owner)
    if(this.$store.state.shopArticles[(this.infos.session+this.infos.owner)] === undefined) this.loading = true
    else {
      this.list = this.$store.state.shopArticles[(this.infos.session+this.infos.owner)]
      this.listAll = this.list
      return false
    }
    
    const axios = require('axios')
    axios.post(this.$store.state.stockwareUrl + 'api/admin/sassayerProdS.php', {
      type:"productList",
      owner:this.infos.owner,
      session:this.infos.session
    })
    .then(({data}) => {
      
      //------ set list --------
      let list = [], max = data.length, i=0
      for(i=0;i<max;i++) {
        let imgs = this.setImages(data[i])
        list.push({...data[i],images:imgs})
      }
      this.list = list
      this.listAll = list
      this.$store.commit('setShopArticles',{
        session:this.infos.session,
        owner: this.infos.owner,
        articles: list
      })
      this.loading = false
    })
    .catch((error) => {
      this.$refs.reload.show()
      console.log(error)
      this.loading = false
    })
  },
 }
}

<template>
    <div v-show="Display" class="itemContainer">
        <div class="itemHeader">
                <div class="itemHLeft">
                    <div :style="{backgroundImage:'url('+(infos.logoImage == '' ? '':  sBsrl+infos.logoImage)+')'}" class="itemHProfile"></div>
                    <div>
                        <div>{{ infos.shop }}<StarElt :rate="infos.rate" style="margin-left:3px;top:4px" Width="17px"/></div>
                        <span>{{ geoDistance($store.state.coords,infos) }}</span>
                    </div>    
                </div>
                <AndroidButton @clicked="close"><img src="./img/cross.svg" /></AndroidButton>
        </div>
    <div class="item" ref="itemBox">
        <div class="itemBody">
            <div class="itemImg">
                <MyProfile style="z-index:3" @visualize="$refs.visualizer.open(images,infos.prodDescription)" :infos="infos" :images='images'/>
            </div>
            <div class="itemInfos">
                <div><h4>Boutique</h4><span>{{ infos.shop }}</span></div>
                <div><h4>Infos boutique </h4><span>{{ infos.description }} Tel +237 {{infos.whatsappTel}}.</span></div>
                <div><h4>Lieu</h4>
                    <span>{{ infos.city }}, 
                        <span v-show="infos.quater!=''" >Quartier {{infos.quater}}</span></span>
                </div>
                <AndroidButton @clicked="toMap" class="itemLocate" radius="0.4em">  Localiser la boutique <img style="height:22px" src="./img/google-maps.svg" /></AndroidButton> 
                <AndroidButton @clicked="toWhatsapp" class="itemLocate" radius="0.4em">  Contacter la boutique <img src="./img/whatsapp.svg" /> </AndroidButton> 
            </div>
            <div class="itemPublisher">
                <AndroidButton @clicked="$refs.shopArticle.show(infos)" radius="0.4em"> <img src="./img/plus.svg"/>Voir d'autre objet de la boutique</AndroidButton>
                <AndroidButton v-show="$store.state.favorites[(infos.session+infos.owner)] == undefined"   @clicked="addFavorite" radius="0.4em"> <img src="./img/favorite.svg"/>Ajouter la boutique aux favoris</AndroidButton>
                <AndroidButton v-show="$store.state.favorites[(infos.session+infos.owner)] != undefined" radius="0.4em"> <img src="./img/favorite.svg"/>Boutique déjà dans vos favoris</AndroidButton>
            </div>
        </div>

        <Contact ref="contact" :infos="{name:infos.creator, type:'Me contacter à',contact:infos.tel}"/>
        
        <!-- <ChatBox ref="chatBox"/> -->
        <Visualizer style="z-index:3" ref="visualizer"/>
        <ShopArticle style="z-index:3" ref="shopArticle"/>
        <Confirm style="z-index:3" ref='confirm' @clicked="confirming"/>
        <LLoading1 style="z-index:3" :visible="loading"/>
        <AlertG style="z-index:3" ref="alertG"/>
    </div>
    </div>
</template>
<style src="./item.css">
</style>
<script src = "./item.js">
</script>

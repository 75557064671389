import LPublication from '../lPublication/LPublication'
export default {
  name: 'service',
  props: {
    amount: {
      type: Number,
      default: 4
    },
    start: {
      type: Number,
      default: 0
    },
    elements: {
      type:Array,
      default:[]
    }
  },
  components: {
    LPublication
  },
  emits:["visualize","infos"],
  data () {
    return {
 
    }
  }
}

import ShopArticle from '@/components/shopArticle/ShopArticle'
import StarElt from '@/components/global/StarElt'
import FInfos from './fInfos/FInfos'
import Confirm from '@/components/global/confirm/Confirm'
import LLoading1 from '@/components/global/lLoading1/LLoading1'

export default {
  name: 'shopArticle',
  components: {
    ShopArticle,
    StarElt,
    FInfos,
    Confirm,
    LLoading1
  },
  data () {
    return {
      loading:false,
      Display:true,
      toDelete:{},
      sBsrl:this.$store.state.stockwareUrl+'images/',
      favorites:[],
      favoritesAll:[]
    }
 },
 computed: {
 },
 methods: {
  search (word) {
    let k = this, result={}
    let listAll = this.favoritesAll
    let w =  k.removeAccents(word.toLowerCase())

    for(const index in listAll ) {
      let pd = k.removeAccents(listAll[index].shop)
      if(pd.includes(w)) result[index] = listAll[index]
    }

    this.favorites = result
  },
  removeAccents (str) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
  },
  confirming (message) {
    if(message==='yes') {
      this.deleteFavorite()
    }
  },
  deleting (inf) {
    this.toDelete = inf
    this.$refs.confirm.show(`Voulez-vous vraiment supprimer
    <span style="color:rgb(238, 64, 64)">${inf.shop}</span> de vos boutiques favorites?`)
  },
  deleteFavorite() {
    if(!this.$store.state.user.connected) return

    this.loading = true
    const axios = require('axios')
    axios.post(this.$store.state.baseUrl + 'favorite.php', {
      type:"delete",
      owner:this.toDelete.owner,
      session:this.toDelete.session,
      userId: this.$store.state.user.id,
    })
      .then(() => {
        let newFav = {...this.$store.state.favorites}
        delete newFav[(this.toDelete.session+this.toDelete.owner)]
        this.$store.commit('setFavorites',newFav)
        this.loading = false        
      })
      .catch((error) => {
        console.log(error)
        this.loading = false
      })
  },
  getFavorite() {
    if(!this.$store.state.user.connected) return

    if(this.$store.state.favorites.length == 0) this.loading = true
    const axios = require('axios')
    axios.post(this.$store.state.baseUrl + 'favorite.php', {
      type:"get",
      userId: this.$store.state.user.id,
    })
      .then(({data}) => {
        this.loading = false
        this.getFavInfos(data)
      })
      .catch((error) => {
        console.log(error)
        this.loading = false
      })
    },
    getFavInfos (favs) {
      if(favs.length == 0) return
      if(this.$store.state.favorites.length == 0)  this.loading = true
      const axios = require('axios')
      axios.post(this.$store.state.stockwareUrl + 'api/admin/sassayerProdS.php', {
        type:"shopInfos",
        favs: favs,
      })
        .then(({data}) => {
          //console.log(data)
          let i = 0,favorites = {}
          for(i=0;i<data.length;i++) {
            //if(i==0) favorites =  {[data[i].session+data[i].owner]:{...data[i]}}
            favorites = {...favorites,[data[i].session+data[i].owner]:{...data[i]}}
          }
          //console.log(favorites)
          this.$store.commit('setFavorites',favorites)
          this.$store.commit('setFavoritePage',{loaded:true})
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
 },
 watch: {
  '$store.state.user.connected' (newVal) {
    if(newVal) this.getFavorite()
  },
  '$store.state.favorites' (newVal) {
    this.favorites = newVal
    this.favoritesAll = newVal
  },
  '$store.state.toSearch' (newVal) {
    if(newVal.page==="favorite") this.search(newVal.search)
  }
 },
 mounted () {
   this.favorites = this.$store.state.favorites
   this.favoritesAll = this.favorites
   if(this.$store.state.favoritePage.loaded) return //--- if the page loaded don't look for datas
   this.getFavorite()
 },
 beforeRouteEnter(to, from) {
  if(typeof from.name == 'undefined') return {name:'firstPage',params:{next:'favorite'}}
 },
 beforeRouteLeave (/*to, from*/) {
    if(this.$refs.confirm.Display == true ) {
      this.$refs.confirm.Display = false
      return false
    }
    if(this.$refs.fInfos.Display == true ) {
      this.$refs.fInfos.close()
      return false
    }
    if(this.$refs.shopArticle.Display==true) {
      this.$refs.shopArticle.close()
      return false
    }
    if(this.$store.state.logDisplay.inscription == true || this.$store.state.logDisplay.connexion == true) {
      this.$store.commit('setLogDisplay',{
        inscription:false,
        connexion:false
      })
      return false
    }
    if(this.$store.state.DisplayNavConfirm) {
      this.$store.commit('setDisplayNavConfirm',false)
      return false
    }

  }
}

<template>
	<div class="starElt">
		<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			stroke="rgb(238, 64, 64)" stroke-width="3"
			viewBox="0 0 55.867 55.867" 
			:style="{fill:'rgb(238, 64, 64)', width:Width, minWidth:Width}"
			style="enable-background:new 0 0 55.867 55.867;" xml:space="preserve">
		<path d="M55.818,21.578c-0.118-0.362-0.431-0.626-0.808-0.681L36.92,18.268L28.83,1.876c-0.168-0.342-0.516-0.558-0.896-0.558
			s-0.729,0.216-0.896,0.558l-8.091,16.393l-18.09,2.629c-0.377,0.055-0.689,0.318-0.808,0.681c-0.117,0.361-0.02,0.759,0.253,1.024
			l13.091,12.76l-3.091,18.018c-0.064,0.375,0.09,0.754,0.397,0.978c0.309,0.226,0.718,0.255,1.053,0.076l16.182-8.506l16.18,8.506
			c0.146,0.077,0.307,0.115,0.466,0.115c0.207,0,0.413-0.064,0.588-0.191c0.308-0.224,0.462-0.603,0.397-0.978l-3.09-18.017
			l13.091-12.761C55.838,22.336,55.936,21.939,55.818,21.578z"/>
		</svg>
		<span>{{ rate }}</span>
	</div>
</template>
<script>
export default {
  name: 'starElt',
  props: {
    selected:  {
      type: Boolean,
      default: false
    },
	Width:  {
      type: String,
      default: '17px'
    },
	rate: {
		type:Number,
		default:1
	}
  }
}
</script>
<style>
	.starElt{
		position: relative;
		display:inline-flex;justify-content: center;align-items: center;
		margin-right: 3px;

	}
	.starElt > span {
		position: absolute;
		left: 0px;
		top:0px;
		width: 100%;
		height: 100%;
		display: flex;justify-content: center;align-items: center;
		font-size: 8px;
		font-weight: bold;
		padding-top: 2px;
		padding-bottom: 1px;
		color:white
	}
</style>

import StarElt from '@/components/global/StarElt'
//import { facebookDate } from '@/facebookDate'
import AndroidButton1 from './AndroidButton1'

export default {
  name: 'lPublication',
  components:{
    StarElt,
    AndroidButton1
  },
  props: {
    datas: {
      type: Object,
      default: {id:'-1',description:'pumom upsum ipsumum aruminum'},
    }
  },
  emits:["visualize","infos"],
  data () {
    return {
      images:[],
      data1:{description: "Intel Pentium4 2.85GhZ 4Gga de RAM,Intel Pentium4 2.85GhZ 4Gga de RAM",
        shopName:'Mini mokolo Campsonel Oyom-Abang',
        distance:'6'
      }
    }
  },
  methods: {
    visualize () {
      /*if(this.images.length == 0) this.$emit('infos',{infos:this.datas,images:this.images})
      else this.$emit('visualize',this.images)*/
    }
    /*fbDate (d) {return facebookDate(d)},
    toObject () {
      this.$store.commit('setCurrentObject',this.datas)
      this.$router.push({name:'object', params: {id: this.datas.id }})
    }*/
  },
  mounted () {
    let bUrl = this.$store.state.stockwareUrl + 'images/'
    if(this.datas.produitImage0 != '' ) this.images.push(bUrl+this.datas.produitImage0)
    if(this.datas.produitImage1 != '' ) this.images.push(bUrl+this.datas.produitImage1)
    if(this.datas.produitImage2 != '' ) this.images.push(bUrl+this.datas.produitImage2)
    if(this.datas.produitImage3 != '' ) this.images.push(bUrl+this.datas.produitImage3)
  }
}

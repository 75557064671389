<template>
    <div class="geolocation">
    </div>
    <AlertG ref="alertG"/>
</template>

<style>
.geolocation {
    position: fixed;
    opacity: 0;
    pointer-events: none;
}
</style>
<script>
export default {
    name: 'geolocation',
    data () {
        return {
            counter:0
        }
    },
    methods: { 
        getLocation () {
            function waitDelay(t){
                return new Promise((resolve) => {
                    setTimeout(resolve, t);
                })
            }
            let k = this
         if ("geolocation" in navigator) {

            // Get the user's current position
            navigator.geolocation.getCurrentPosition(
                function (position) {
                    k.$store.commit('setCoords',position.coords)
                    console.log(position)
                    waitDelay(10000)
                    k.getLocation()
                },
                function (error) {
                    console.log(error)
                    if(k.counter%10 == 0 && k.$store.state.coords == null )  k.$refs.alertG.displaying(`<b>Erreur de localisation:</b> ${error.message} <br><br>
                        1- Assurez vous que vous avez activé la localisation. Cela permet de rechercher 
                        les boutiques les plus proches de votre zone.<br><br>
                        2- Si le problème persiste, mettez à jour votre navigateur.
                    `)
                    k.counter++
                    /*let coords = {longitude:k.counter,latitude:2+k.counter,accuracy:2}
                    console.log(coords.longitude)
                    k.$store.commit('setCoords',coords)*/
                    waitDelay(5000)
                    k.getLocation()
                },
                {
                    enableHighAccuracy:true,
                    timeout:5000
                }
            )
        } 
        else {
            k.$refs.alertG.displaying(`La <b>geolocation</b> n'est pas supportée.Veuillez vous
            rassurer que votre appareil supporte la fonctionalité de localisation.`)
        }
        }
    },
    mounted() {
        this.getLocation()
    }
}
</script>

import InputI0 from '@/components/inscription/InputI0.vue'
import ButtonI0 from '@/components/inscription/ButtonI0.vue'
import Infos from '@/components/global/infos/Infos'
import LLoading1 from '@/components/global/lLoading1/LLoading1'
import AlertG from '@/components/global/alertG/AlertG'
export default {
  name: 'inscription',
  components: {
    InputI0,
    ButtonI0,
    Infos,
    LLoading1,
    AlertG
  },
  data () {
    return {
      loading:false,
      Display:true,
      DispErr: false,
      error:''
    }
  },
  emits: ['onnexion'],
  methods: {
    show () {
      this.$store.commit('setLogDisplay',{
        inscription:true,
        connexion:false
      })
    },
    close () {
      this.$store.commit('setLogDisplay',{
        inscription:false,
        connexion:this.$store.state.logDisplay.connexion
      })
    },
    closing (event) {
      if( event.target == this.$refs.main ) {
        this.close()
      }
    },
    toConnexion () {
      this.$emit('connexion')
      this.Display = false
    },
    save () {
      if (!this.validation()) return
      this.DispErr = false
      this.loading = true
      const axios = require('axios')
      //axios.defaults.withCredentials = true
      axios.post(this.$store.state.baseUrl + 'inscription.php', {
        type:'inscription',
        name: this.$refs.surname.message,
        contact: this.$refs.contact.message,
        password: this.$refs.password.message
      })
        .then(({data}) => {
          this.loading = false
          if(data=='exist') {
            this.DispErr = true
            this.error = 'Compte déjà existant'
            this.$refs.alertF.displaying('<span style="color:rgb(238, 64, 64)">Compte déjà existant.</span>')
          }
          else {
            this.$refs.alertG.displaying('<b>Compte créé avec success!</b>')
            localStorage.setItem('user__',JSON.stringify(data))
            this.$store.commit('connexion',{...data,connected:true})
          }
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    validateEmail (code) {
      this.loading = true
      const axios = require('axios')
      //axios.defaults.withCredentials = true
      axios.post(this.$store.state.baseUrl + 'inscription.php', {
        type:'validateEmail',
        code: code
      })
        .then(({data}) => {
          this.loading = false
          if(data === 'success') {
            this.$refs.alertG.displaying('Success')
            this.$router.push('/inscription2')
          }
          else this.$refs.alertG.displaying('le code est incorrect')
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    validation () {
      let t = this.$refs.contact.message+''
      this.DispErr = false
      //let regex2 = /^[0-9]{6,11}.$/
      if (this.$refs.surname.message.length < 3) {
        this.error = 'Nom trop court ou incorrect'
        this.DispErr = true
        this.$refs.alertF.displaying('<span style="color:rgb(238, 64, 64)">Nom trop court ou incorrect.</span>')
        return false
      }
      if(t.length != 9 || t[0] !=6) {
        this.error = 'Numéro téléphone incorrect'
        this.DispErr = true
        this.$refs.alertF.displaying('<span style="color:rgb(238, 64, 64)">Numéro de téléphone incorrect.</span>')
        return false
      }
      if (this.$refs.password.message.length < 4 ) {
        this.error = 'Mot de passe trop court ou incorrect'
        this.DispErr = true
        this.$refs.alertF.displaying('<span style="color:rgb(238, 64, 64)">Mot de passe trop court ou incorrect.</span>')
        return false
      }
      if (this.$refs.password.message !== this.$refs.confirmpw.message) {
        this.error = 'Les mots de passe ne correspondent pas'
        this.DispErr = true
        this.$refs.alertF.displaying('<span style="color:rgb(238, 64, 64)">Les mots de passe ne correspondent pas.</span>')
        return false
      }
      return true
    },
    //-- cookie useful functions ------------
    setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      let expires = "expires="+d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
  }
}

<template>
    <div class="jFooter">
        Copyright 2025. lobjectiviste prod All right reserved.        
        <div>    
            <a><img src="./img/facebook.svg"/></a>
            <a><img src="./img/gmail.svg"/></a>
            <a href='https://wa.me/237652952941' ><img src="./img/whatsapp.svg"/></a>
        </div>
    </div>
</template>
<style>
.jFooter {
    position: absolute;
    bottom: 0px;
    left:0px;
    width:100%;
    background-color: #F0F1F2;
    color:#4D4D4D;
    text-align: center;
    display: flex; justify-content: center;align-items: center;
    padding-top:10px;
    padding-bottom: 10px;
    font-size: 12px;
}
.jFooter > div > a {
    height: 30px;
    width: 30px;
    display: inline-flex;justify-content: center;align-items: center;
    margin-left: 10px;
    margin-right: 10px;
    border: solid 1px silver;
    border-radius: 0.4em;
    cursor: pointer;
}
.jFooter > div > a > img {
    width: 20px;
}
/* ---------------- now managment of the responsive ---------------------*/
@media screen and (max-width: 800px){
    .jFooter {
        flex-direction: column-reverse;
    }
    .jFooter > div {
        margin-bottom: 10px;
    }
    .jFooter > div > img {
        margin: 10px;
        margin-top: 0px;
    }
}
</style>

import Visualizer from '@/components/global/visualizer/Visualizer'

export default {
  name: 'MyProfile',
  components: {
    Visualizer
  },
  props: {
    message: String,
    infos:{
      type:Object,
      default:{}
    },
    images:{
      type:Array,
      default:[]
    }
  },
  emits:["visualize"],
  data () {
    return {
      position: 0,
    }
  },
  methods: {
    visualize (images) {
      this.$refs.visualizer.open(images)
    },
    move (i) {
      if ((this.position >= (-this.numb*100 + 200) && i === -1) || (this.position < 0 && i === 1)) {
        this.position += i * 100
      }
      //let index = Math.abs(this.position)/100
      //this.$emit('message',this.elements[index].message)
    }
  },
  watch :{
    images () {
      this.position = 0
    }
  },
  computed: {
    Position () {
      return this.position + '%'
    },
    numb () {
      return this.images.length
    }
  }
}

<template>
    <div class="searchBar1">
        <input v-model="search" @input="$emit('quickSearch',search)" @search="searching" 
            type="search" :placeholder="placeholder" ref="input">
        <img @click="searching" src="./img/lens1.svg"/>
    </div>
</template>
<script>
export default {
name: 'searcBar1',
    emits:["search","quickSearch"],
    props: {
        placeholder:{
            default:''
        }
    },
    data () {
        return {
            search:''
        }
    },
    methods: {
        searching () {
            this.$emit('search',this.search)
            this.$refs.input.blur()
        }
    }
}
</script>
<style>
    .searchBar1 {    
        box-sizing: border-box;
        width: 100%;
        height: 35px;
        border-radius: 10em;
        display: flex;
        background-color: #F0F1F2;
        overflow: hidden;
        padding-left: 15px;
        padding-right: 15px;
    }
    .searchBar1 > input {
        border: none;
        width:100%;
        font-size: 13px;
        background-color: #F0F1F2;
    }
    .searchBar1 > img {
        width: 22px;
        cursor:pointer;
    }

</style>

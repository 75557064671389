<template>
    <div v-show="Display" class="favoriteContainer">
        <div class="favorite">
            <div v-show="false" class="favoriteFav">Boutique favoris</div>
            <div v-for="(fav,index) in favorites" :key="index" class="favoriteElt">
                <span :style="{backgroundImage:'url('+(fav.logoImage == '' ? '':  sBsrl+fav.logoImage)+')'}">
                    <span>
                        <StarElt :rate="fav.rate" style="margin-right:0px;" />
                    </span>
                </span>
                <div class="favoriteEltInfos">
                    <div>{{ fav.shop.slice(0,23) }}{{ fav.shop.length>23 ? '..' :'' }}</div>
                    <span>{{ geoDistance($store.state.coords,fav) }}</span>
                    <span class="favoriteCity">{{ fav.city }}</span>
                    <div v-show="false">
                        <StarElt style="margin-left:3px;top:4px" Width="17px"/>
                    </div>
                    <p class="favoriteEP">
                        <AndroidButton @clicked="$refs.fInfos.show(fav)" radius="3em"><img src="./img/infos.svg"/></AndroidButton>
                        <AndroidButton @clicked="$refs.shopArticle.show(fav)" radius="3em"><img src="./img/list.svg"/></AndroidButton>
                    </p>
                </div>
                <AndroidButton @clicked="deleting(fav)" radius="3em"><img src="./img/cross.svg"/></AndroidButton>
            </div>



        </div>
        <ShopArticle ref="shopArticle" style="z-index:3" />
        <FInfos ref="fInfos" style="z-index:3" />
        <Confirm style="z-index:3" ref='confirm' @clicked="confirming"/>
        <LLoading1 style="z-index:3" :visible="loading"/>
    </div>
</template>
<style src="./favorite.css">
</style>
<script src = "./favorite.js">
</script>

<template>
    <div class="firstPage">   
    </div>
</template>
<style>
.firstPage {

}
</style>
<script>
// this component allows to ensure that on the first load of the router event are active
// so we redirect once to this page after loading
export default {
  name: 'Firstpage',
  mounted () {
      //if(this.$route.params.next == -1) this.$router.go(-1)
      if(!this.$store.state.firstPageLoaded) {
        this.$store.commit('setFirstPageLoaded',true)
        this.$router.push({name:this.$route.params.next})
      }
      else window.history.go(-1)
  },
}

</script>

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import AndroidButton from '@/components/global/AndroidButton'
import AlertG from '@/components/global/alertG/AlertG'
import geoDistance from './geoDistance'

const app = createApp(App)
app.mixin(geoDistance) 
app.component('AndroidButton',AndroidButton)
app.component('AlertG',AlertG)
app.use(router)
app.use(store)
app.mount('#app')


//ALTER DATABASE brader CHARACTER SET = utf8mb4 COLLATE = utf8mb4_unicode_ci;
//ALTER TABLE Message CONVERT TO CHARACTER SET utf8mb4 COLLATE utf8mb4_unicode_ci;
//ALTER TABLE Message MODIFY content TEXT CHARSET utf8mb4;
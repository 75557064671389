import { createStore } from 'vuex'

export default createStore({
    state () {
      return {
        //baseUrl: 'http://127.0.0.1:3001/',
        //baseUrl: 'http://192.168.43.251:3001/',
        baseUrl: 'https://sassayer.com/',
        //stockwareUrl:'http://127.0.0.1:3000/',
        //stockwareUrl: 'http://192.168.43.251:3000/',
        stockwareUrl:'https://stockware.sassayer.com/',
        firstPageLoaded:false,
        toSearch:{page:'searching',search:''},
        logDisplay:{inscription:false,connexion:false},// if inscription or connexion are Displayed
        favorites:{}, // favorite shop
        shopArticles:{},
        favoritePage: {loaded:false},
        DisplayNavConfirm:false,
        coords:{longitude:0,latitude:0,accuracy:0},
        //----------------------------
        types:["Téléphone","Ordinateur","Véhicule","Vêtements","Accessoire","Meuble","Appareil électronique",
        "Autres"],
        user: {connected: false,id:'',name:'',tel:'',img:''},
        objectList:{
          isResearch:true,
          created:false,
          list:[],
          type:'Téléphone',
          srcoll:0,
          nbrRow:[0],
          city:'Yaounde',
          search:''
        },
        discussions:{},
        unreadCount:{overall:0},
        firstLoad:'yes', // is messages already loaded or not
        lastMsgId:0,
        messageId:-1
      }
    },
    mutations: {
      setFirstPageLoaded (state,playload) {
        state.firstPageLoaded = playload
      },
      setSearch (state,playload) {
        state.toSearch = playload
      },
      setLogDisplay (state,playload) {
        state.logDisplay = playload
      },
      setDisplayNavConfirm (state,playload) {
        state.DisplayNavConfirm = playload
      },
      setFavorite (state,playload) {
        //console.log(state.favorites)
        //if(state.favorites.length == 0) state.favorites = {[playload.session+playload.owner]:{...playload}}
        state.favorites = {...state.favorites,[playload.session+playload.owner]:{...playload}}
        localStorage.setItem('favorites__',JSON.stringify(state.favorites))
      },
      setFavorites (state,playload) {
        state.favorites = playload
        localStorage.setItem('favorites__',JSON.stringify(state.favorites))
      },
      setFavoritePage (state,playload) {
        state.favoritePage = playload
      },
      setShopArticles (state,playload) {
        state.shopArticles[(playload.session+playload.owner)] = playload.articles
      },
      setCoords (state,playload) {
        state.coords = playload
      },
      //----------------------------
      connexion (state,playload) {
        state.user.connected = playload.connected
        state.user.id = playload.id
        state.user.name = playload.name
        state.user.tel = playload.tel
        state.user.img = playload.img  
      },
      saveUserImg (state,playload) {
        state.user.img = playload
      },
      setObjectList (state,playload) {
        state.objectList.created = playload.created
        state.objectList.list = playload.list
        state.objectList.type = playload.type
        state.objectList.scroll = playload.scroll
        state.objectList.nbrRow = playload.nbrRow
        state.objectList.city = playload.city
        state.objectList.search = playload.search
      },
      setDiscussions (state,playload) {
        state.discussions = playload.discussions
        state.unreadCount = playload.count
        state.firstLoad = playload.firstLoad
        state.lastMsgId = playload.lastMsgId
      },
      setFirstLoad (state,playload) {
        state.firstLoad = playload
      },
      setUnread (state,playload) {
        state.unreadCount = playload
      },
      setMessageId (state,playload) {
        state.messageId = playload
      }
    }
  })

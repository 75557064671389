<template>
    <div class="zoomable">
        <img :style="{maxWidth:'200%',width:'200%'}" 
        @touchstart="start"
        @touchmove="move"
        @touchend="end"
        class="zoomable" :src="image"/>
    </div>
</template>
<style>
@keyframes blink {
  from {
    background-color: rgba(128, 128, 128, 0.5);
  }
  to {
    background-color: transparent;
  }
}
.zoomable {
    max-width: 100vw;
    max-height: 100vh;
    overflow: scroll;
    border: solid 2px red;
}
</style>
<script>
  export default {
  name: 'Zoomable',
  props: {
    image: {
      type:String,
      default:''
    }
  },
  data () {
      return {
          zoomFactor:4
      }
  },
  computed: {
      Width () {
          return 100*this.zoomFactor
      }
  },
  emits:["clicked"],
  methods: {
      start (e) {
        if (e.touches.length === 2) {
            const distance = Math.hypot(e.touches[0].clientX - e.touches[1].clientX, e.touches[0].clientY - e.touches[1].clientY);
            console.log('Début du zoom : distance = ' + distance);
        }
      },
      move (e) {
        if (e.touches.length === 2) {
            const distance = Math.hypot(e.touches[0].clientX - e.touches[1].clientX, e.touches[0].clientY - e.touches[1].clientY);
            console.log('Zoom en cours : distance = ' + distance);
        }
      },
      end (e) {
        if (e.touches.length === 0) {
            console.log('Fin du zoom');
        }
      }
  }
}
</script>

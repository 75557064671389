<template>
  <div :style="{width:Display,opacity:Opacity}" class="visualizer">
    <div 
      draggable="false"
      @touchstart="dragStart"
      @mousedown="dragStart"
      @mouseup ="dragEnd"
      @touchend="dragEnd"
      @mousemove="dragging"
      @touchmove="dragging"
      :style="{ left:Position,transition:transition, width:(100*elts.length)+'%' }"
      class="visualContent"
    >
    <div v-for="(elt,index) in elts" :key="index"><img :style="{width:ImgWidth,maxWidth:ImgWidthM}" draggable="false" :src="elt"/></div>
    </div>
    <div class="visualPoints">
      <span v-for="(elt,index) in elts" :key="index"></span>
    </div>
    <AndroidButton @clicked="DisplayDesc=true" v-show="description !='' && !DisplayDesc" class="visualInfos" radius="3em" >
      <img src="./help.svg" />
    </AndroidButton>
    <AndroidButton @clicked="DisplayDesc=false" v-show="description !='' && DisplayDesc" class="visualInfos1" radius="0.4em" >
      <span>{{ description }} </span> 
    </AndroidButton>
    <AndroidButton radius="4em" @clicked="close" class="visualCross"><img src="./more.svg"/></AndroidButton>
  </div>
</template>
<style>
.visualizer {
  box-sizing: border-box;
  position: fixed;
  left:0px;
  top:0px;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 1;
  overflow: hidden;
  transition: opacity 0.4s;
}
.visualContent {
    position:relative;
    width: 300%;
    height: 100%;
    display: flex;
    transition: left 1s;
}
.visualContent > div {
    width: 100%;
    height: 100%;
    display: flex;justify-content: center;align-items: center;
    overflow: auto;
}
.visualContent > div > img {
    max-width: 100%;
}
.visualPoints {
  position:absolute;
  top: 0px;
  margin-top: 10px;
  width: 100%;
  display: flex;justify-content: center;align-items: center;
}
.visualPoints > span {
  width: 7px;
  height: 7px;
  margin-left: 3px;
  margin-right: 3px;
  background-color: white;
  border-radius:3em;
}
.visualizer >  .visualInfos {
  position: absolute;
  left: 0px;
  bottom:0px;
  padding: 0px;
  margin-left:4% ;
  margin-bottom: 4%;
  font-size: 13px;
  width: 30px;
  height: 30px;
  border-radius:3em ;
  background:rgb(0, 0, 0,0.5);
  display: flex;justify-content: center;align-items: center;
  color: white;
}
.visualInfos > img {
  height: 15px;
}
.visualizer >  .visualInfos1 {
  position: absolute;
  left: 0px;
  bottom:0px;
  padding: 10px;
  padding-left:20px ;
  padding-bottom: 20px;
  font-size: 13px;
  min-width: 140px;
  background:rgb(0, 0, 0,0.5);
  color: white;
}
.visualizer > .visualCross {
  position: absolute;
  right: 0px;
  top:0px;
  margin-right: 10px;
  margin-top: 10px;
  width: 28px;
  height: 28px;
  border-radius:7em;
  display: flex;justify-content: center;align-items: center;
  background-color: rgb(255, 255, 255,0.7);
  cursor: pointer;
  padding: 0px;
}
.visualCross > img {
  width: 17px;
}
</style>
<script src="./visualizer">
</script>
<template>
    <AndroidButton @clicked="$emit('infos',{infos:datas,images:images})" style="z-index:1;justify-content:flex-start;align-items:flex-start;padding:0px;" 
        radius="0.7em" class="lPublication" >
        <div class="lPImg1"></div>
        <div radius="0em"
        class="lPImg" :style="{backgroundImage:'url('+(images.length > 0 ? images[0]:'')+')'}">
        </div>
        <div radius="0em"  class="lPZone">
            <div class="lPText" > 
                <!-- <div class="lPDescription">{{ data1.description.slice(0,45) }}{{ data1.description.length>45 ? '...' :'' }}</div> -->
                <div class="lPDescription"> 
                    {{ datas.nomProduit.slice(0,45) }}{{ datas.nomProduit.length>45 ? '..' :'' }}
                </div>
                <div class="lPType">
                    <div>{{ datas.prixProduit }} FCFA</div>
                </div>
                <div class="lPWhere">{{ datas.shop.slice(0,20) }}{{ datas.shop.length>20 ? '..' :'' }} </div>
                <div class="lPWhere1">{{ datas.city }} </div>
                <div >{{ geoDistance($store.state.coords,datas) }}<img src="./img/location.svg" /></div>
            </div>
        </div>
        <div v-show="datas.disponible==1" class="lPDispo" >dispo</div>
        <StarElt class="lPRate" style="margin-left:5px" :rate="datas.rate"/>
    </AndroidButton>
</template>
<style src="./lPublication.css">
</style>
<script src="./lPublication.js">
</script>

export default {
  name: 'reload',
  props: {
    persistant: {
      type: Boolean,
      default:true
    }
  },
  emits:["reload"],
  data () {
    return {
      Display:false
    }
  },
  methods: {
    reloading () {
      this.$emit('reload')
      this.Display = false
    },
    show () {
      this.Display = true
    },
    close () {
      this.Display = false
    },
    closing(event) {
      if(event.target == this.$refs.main && this.persistant) this.Display = false
    }
  }
}

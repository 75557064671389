<template>
    <div ref="main" @scroll="showScrollTop" class="objectList">
       
        <div style="z-index:0" class="objectListBody">
            <div class="objectListSearch">
                <div v-show="false" style="z-index:1" class="objectListSHead">
                    <select class="objectListType"
                     @change="search('type',infos.type)" v-model="infos.type">
                        <option v-for="(option,index) in this.$store.state.types" :key="index">
                            {{option}}
                        </option>
                    </select>
                    <div ref="objectListInputS" class="objectListCities">
                        <InputSearch ref="objectListCities" type="Ville" :id="'objectList'"  
                            @newVal="(val)=>search('city',val)" :placeholder="'Saisir la ville'" />
                    </div>
                </div>
                <div style="z-index:0" class="servicesMain">
                    <h5>Liste des objets trouvés</h5>
                    <div v-show="infos.list.length == 0" class="objectListNothing" >Aucun résultat.</div>
                    <Service
                        v-for="(row,i) in infos.nbrRow" :key="i"
                        :elements='infos.list.slice(16*i,16+16*i)' 
                        :amount='4'
                         @visualize="(img)=>$refs.visualizer.open(img)"
                         @infos="(infos)=>$refs.item.show(infos)"
                        class="landSlider0"
                    />
                    <Service
                        v-for="(row,i) in infos.nbrRow" :key="i"
                        :elements='infos.list.slice(16*i,16+16*i)' 
                        :amount='3'
                        @visualize="(img)=>$refs.visualizer.open(img)"
                        @infos="(infos)=>$refs.item.show(infos)"
                        class="landSlider1"
                    />
                    <Service
                        v-for="(row,i) in infos.nbrRow" :key="i"
                        :elements='infos.list.slice(16*i,16+16*i)' 
                        :amount='2'
                        @visualize="(img)=>$refs.visualizer.open(img)"
                         @infos="(infos)=>$refs.item.show(infos)"
                        class="landSlider2"
                    />
                    <Service
                        v-for="(row,i) in infos.nbrRow" :key="i"
                        :elements='infos.list.slice(16*i,16+16*i)' 
                        :amount='1'
                        @visualize="(img)=>$refs.visualizer.open(img)"
                         @infos="(infos)=>$refs.item.show(infos)"
                        class="landSlider3"
                    />

                    <div class="objectListMore">
                        <AndroidButton radius="0.4em" v-show="moreVisible" @click="moreResults"> Plus de résultats</AndroidButton>
                    </div>
                </div>
            </div>
        

        </div>

        <div class="objectListFooter"><JFooter/></div>
        <!--<ChatBox ref="chatBox"/>-->

        <ScrollTop ref="scrollTop" @click="scrollingTop" />
        <LLoading1 :visible="loading"/>
        <Item ref="item" style="z-index:2"/>
        <ShopArticle style="z-index:2"/>
        <Visualizer ref="visualizer" style="z-index:2"/>
        <Reload ref="reload" @reload="getProducts" style="z-index:2"/>
        
    </div>
</template>
<style src="./objectList.css">
</style>
<script src = "./objectList.js">
</script>

<template>
    <div v-show="$store.state.DisplayNavConfirm" class="confirm">
        <div class="confirmBox">
            <div class="confirmText" v-html="text"></div>
            <div class="confirmbutton">
                <div @click="clicked('yes')" >oui</div>
                <div @click="clicked('no')" style="background-color:#212121;" >non</div>
            </div>
        </div>
    </div>
</template>
<style src="./confirm.css">
</style>
<script src = "./confirmJ.js">
</script>

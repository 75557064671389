export default {
  methods: {
    geoDistance(p1,p2) {
        //p1 = this.$store.state.coords
        if(p2.kind === "noShop") return 'Online shop'
        if(p1.longitude === 0) return "Distance indéterminée"
        //console.log(p1)
        const R = 6371; // Rayon de la Terre en km
        const dLat = (p2.latitude - p1.latitude) * Math.PI / 180;
        const dLon = (p2.longitude - p1.longitude) * Math.PI / 180;
        const lat1Rad = p1.latitude * Math.PI / 180;
        const lat2Rad = p2.latitude * Math.PI / 180;
      
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                  Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1Rad) * Math.cos(lat2Rad);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        let distance = (R * c+((p1.accuracy +p2.accuracy)/2000))*1000
        distance = 'À '+distance<1000 ? parseInt(distance)+'m max' : 'À '+(distance/1000).toFixed(2)+'km max'
  
        return distance
    }
  }
}

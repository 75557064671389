<template>
    <div v-show="Display" class="shopArticleContainer">
        <div class="shopArticleHeader">
            <div class="shopArticleHF"><img src="./img/list.svg" />{{ infos.shop }}</div>
            <div><input @input="search" v-model="word" placeholder="Rechercher" type="search"
                ref="input" @search="$refs.input.blur()"
            /></div>
            <AndroidButton @clicked="close"><img src="./img/cross.svg" /></AndroidButton>
        </div>
        <div class="shopArticle">
            <div class="shopArticleNothing" v-show="list.length==0" >Aucun résultat.</div>
            <AndroidButton v-for="(lt,index) in list" :key="index" radius="0em" class="shopArticleElt"
                @clicked="$refs.visualizer.open(lt.images,lt.prodDescription)"
            >
                <span :style="{backgroundImage:'url('+(lt.images.length == 0 ? '': lt.images[0] )+')'}" ></span>
                <div>
                    <div>{{ lt.nomProduit }} </div>
                    <span>{{ lt.prixProduit }} FCFA</span>
                    <p v-show="lt.disponible==1" >disponible</p>
                </div>
            </AndroidButton>
            <LLoading1 :visible="loading"/>
        </div>
        <Visualizer style="z-index:3" ref="visualizer"/>
        <Reload ref="reload" @reload="getProducts" style="z-index:3"/>
    </div>
</template>
<style src="./shopArticle.css">
</style>
<script src = "./shopArticle.js">
</script>

<template>
    <div v-show="Display" class="fInfosContainer">
        <div class="fInfosHeader">
                <div class="fInfosHLeft">
                    <div :style="{backgroundImage:'url('+(infos.logoImage == '' ? '':  sBsrl+infos.logoImage)+')'}" class="fInfosHProfile"></div>
                    <div>
                        <div>{{ infos.shop }}<StarElt :rate="infos.rate" style="margin-left:3px;top:4px" Width="17px"/></div>
                        <span>{{ geoDistance($store.state.coords,infos) }}</span>
                    </div>    
                </div>
                <AndroidButton @clicked="Display=false"><img src="./img/cross.svg" /></AndroidButton>
        </div>
    <div class="fInfos">
        <div class="fInfosBody">
            <div class="fInfosInfos">
                <div><h4>Boutique</h4><span>{{ infos.shop }}</span></div>
                <div><h4>Description </h4><span>{{ infos.description }} Tel +237{{ infos.whatsappTel }}</span></div>
                <div><h4>Ville</h4>
                    <span>{{ infos.city }}, 
                        <span v-show="infos.quater!=''" >Quartier {{ infos.quater }}</span></span>
                </div>
                <AndroidButton @clicked="toMap" class="fInfosLocate" radius="0.4em">  Localiser la boutique <img style="height:22px" src="./img/google-maps.svg" /></AndroidButton> 
                <AndroidButton @clicked="toWhatsapp" class="fInfosLocate" radius="0.4em">  Contacter la boutique <img src="./img/whatsapp.svg" /> </AndroidButton> 
            </div>
            
        </div>

    </div>
    </div>
</template>
<style src="./fInfos.css">
</style>
<script src = "./fInfos.js">
</script>

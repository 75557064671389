import StarElt from '@/components/global/StarElt'

export default {
  name: 'fInfos',
  components: {
    StarElt
  },
  data () {
    return {
      Display:false,
      infos:{},
      sBsrl:this.$store.state.stockwareUrl+'images/'
    }
 },
 methods: {
  show (inf) {
    this.infos = inf
    this.Display = true
  },
  close () {
    this.Display = false
  },
  toWhatsapp () {
    window.open(`https://wa.me/+237${this.infos.whatsappTel}?text=Hello ! Je suis intéressé par *${this.infos.nomProduit}*.
    `)
  },
  toMap () {
    window.open(`https://www.google.com/maps/search/?api=1&query=${this.infos.latitude},${this.infos.longitude}
    `)
  }
 }
}

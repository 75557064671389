import MyProfile from '@/components/global/myProfile/MyProfile'
import Contact from '@/components/global/contact/Contact'
//import ChatBox from '@/components/global/chatBox/ChatBox'
import StarElt from '@/components/global/StarElt'
import Visualizer from '@/components/global/visualizer/Visualizer'
import ShopArticle from '@/components/shopArticle/ShopArticle'
import Confirm from '@/components/global/confirm/Confirm'
import LLoading1 from '@/components/global/lLoading1/LLoading1'
import Reload from '@/components/global/reload/Reload'

export default {
  name: 'item',
  components: {
    MyProfile,
    Contact,
//    ChatBox,
    StarElt,
    Visualizer,
    ShopArticle,
    Confirm,
    LLoading1,
    Reload
  },
  data () {
    return {
      Display:false,
      loading1:false,
      loading:false,
      infos:{session:-1},
      images:[],
      sBsrl:this.$store.state.stockwareUrl+'images/',
    }
 },
 methods: {
  show (infos) {
    this.infos = infos.infos
    this.images = infos.images
    this.Display = true
  },
  close() {
    if(this.$refs.visualizer.Opacity==1) {
      this.$refs.visualizer.close()
      return
    }
    if(this.$refs.shopArticle.Display==true) {
      this.$refs.shopArticle.close()
      return
    }
    if(this.$refs.confirm.Display==true) {
      this.$refs.confirm.Display = false
      return
    }
    this.images = []
    this.$refs.itemBox.scrollTo(0,0)
    this.Display=false
  },
  toWhatsapp () {
    window.open(`https://wa.me/+237${this.infos.whatsappTel}?text=Hello ! Je suis intéressé par *${this.infos.nomProduit}*.
    `)
  },
  toMap () {
    window.open(`https://www.google.com/maps/search/?api=1&query=${this.infos.latitude},${this.infos.longitude}
    `)
  },
  confirming (message) {
    if(message==='yes') {
      this.addingFavorite()
    }
  },
  addFavorite () {
    if(!this.$store.state.user.connected) {
      this.$store.commit('setLogDisplay',{
        inscription:false,
        connexion:true
      })
      return
    }
    this.$refs.confirm.show(`Voulez-vous vraiment ajouter
    <span style="color:rgb(238, 64, 64)">${this.infos.shop}</span> à vos boutiques favorites?`)
  },
  addingFavorite() {
    this.loading = true
    const axios = require('axios')
    axios.post(this.$store.state.baseUrl + 'favorite.php', {
      type:"save",
      userId: this.$store.state.user.id,
      shop:this.infos.session,
      owner:this.infos.owner
    })
      .then(({data}) => {
        if(data=="done") {
          this.$store.commit('setFavorite',{
            session:this.infos.session,
            shop:this.infos.shop,
            rate:this.infos.rate,
            city:this.infos.city,
            quater:this.infos.quater,
            description:this.infos.description,
            logoImage:this.infos.logoImage,
            owner:this.infos.owner,
            whatsappTel:this.infos.whatsappTel,
            kind:this.infos.kind,
            longitude:this.infos.longitude,
            latitude:this.infos.latitude,
            accuracy:this.infos.accuracy
          })
          this.$refs.alertG.displaying('Ajouté avec succèss !')
        }
        else this.$refs.alertG.displaying('Echec !')
        this.loading = false
      })
      .catch((error) => {
        console.log(error)
        this.$refs.alertG.displaying('Echec !')
        this.loading = false
      })
  },
  setDate (dat) {
    let d = new Date(dat)
    return d.getDate()+' '+this.mois[d.getMonth()] +' '+ d.getFullYear()
  },
  setImages () {
    let bUrl = this.$store.state.stockwareUrl + 'images/'
    if(this.infos.img0 != '' ) this.images.push(bUrl+this.infos.img0)
    if(this.infos.img1 != '' ) this.images.push(bUrl+this.infos.img1)
    if(this.infos.img2 != '' ) this.images.push(bUrl+this.infos.img2)
    if(this.infos.img3 != '' ) this.images.push(bUrl+this.infos.img3)
  },
  showContact () {
    if(this.$store.state.user.connected) this.$refs.contact.displaying()
    else this.$refs.connexion.Display=true
  },
  startChat (msg) {
    if(!this.$store.state.user.connected) {
      this.$refs.connexion.Display = true
      return
    }
    this.$refs.chatBox.loadDiscussion({id:this.infos.userId,img:this.infos.userImg,
      name:this.infos.creator,msg:msg})
  },
 }
}

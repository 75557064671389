import { createWebHistory, createRouter } from 'vue-router'
import ObjectList from '@/components/objectList/ObjectList'
import FirstPage from '@/components/FirstPage'
import Favorite from '@/components/favorite/Favorite'

const routes = [
  {
    path: '/',
    name: 'objectList',
    component: ObjectList
  },
  {
    path: '/firstPage/:next',
    name: 'firstPage',
    component: FirstPage
  },
  {
    path: '/favorite',
    name: 'favorite',
    component: Favorite
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
router